/*
	https://color.adobe.com/Color-Theme-2-color-theme-9075685/edit/?copy=true&base=2&rule=Custom&selected=4&name=Copy%20of%20Color%20Theme%202&mode=rgb&rgbvalues=0.1685999209911621,0.2587935283029195,0.2548721764752607,0.6666452775084595,0.7176289213380428,0.4823251159593523,0.5686016908436397,0.6627239836046601,0.3646759785709922,0.4038913224316824,0.4627163224953124,0.1137016154574252,0.5960537606187388,0.6666457587228617,0.2862447529006746&swatchOrder=0,1,2,3,4
	Colour Scheme:
		1. #2B4241
		2. #AAB77B
		3. #91A95D
		4. #67761D
		5. #98AA49

	Font:
		http://www.fontfabric.com/phenomena-font/
*/

$brand-colour-1: #2B4241;
$brand-colour-2: #AAB77B;
$brand-colour-3: #91A95D;
$brand-colour-4: #67761D;
$brand-colour-5: #98AA49;

$wbs_theme: #DC3522;

@font-face {
	font-family: "Phenomena-Bold";
	src: url("../fonts/phenomena/Phenomena-Bold.otf");
}

body {
	font-family: Phenomena-Bold, Helvetica, Verdana, Sans-Serif;
	font-size: 20px;
}

.navbar {
	background: $brand-colour-2;
	border: 3px solid $brand-colour-5;

	div.navbar-header {
		.navbar-brand {
			background-color: $brand-colour-3;
			text-decoration: underline !important;
			border-right: 3px solid $brand-colour-5;
			color: #fff;
		}
	}
	
	ul.navbar-nav {
		li {
			&.active {
				background-color: $brand-colour-3;
				text-decoration: underline !important;
			}
			a {
				color: #fff !important;

				&:hover {
					background-color: $brand-colour-3;
					text-decoration: underline !important;
				}
			}
		}
	}
}

.btn-themed {
	background-color: $brand-colour-3;
	background-repeat: repeat-x;
	border-color: $brand-colour-5;
	color: #fff;
}

.carousel {
	.carousel-inner {
		background-color: $brand-colour-2;

		.item {
			background-color: $brand-colour-2;
		}
	}
}

.container {
	.themed {
		background-color: $brand-colour-3;
	}
}

.portfolio-wrapper {
	padding-top: 50px;
	overflow:hidden;
	position: relative !important;
	background: $brand-colour-2;
	border: 3px solid $brand-colour-5;
	padding-left: 10px;

	.col-md-4 {
		padding-bottom: 50px;
	}

	.portfolio-image {

		position: relative;

		max-width: 300px;
		max-height: 300px;
		width: 300px;
		height: 300px;

		filter: grayscale(100%);
	}
}

.testimonial-image {
	max-height: 300px;
	max-width: 450px;
	height: 300px;
	width: 450px;
}

.portfolio-active {
	filter: grayscale(0%) !important;

	transition: all 1.5s ease;
}

.wbs-theme {
	color: $wbs_theme;
}

footer {
	padding-bottom: 25px;
}